
import Vue from 'vue'
import MedicoHeader from '@/components/Layout/MedicoHeader.vue'
import authService from '@/services/auth'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'App',
  data: () => ({
    snackbar: false,
    text: '',
    color: '',
    timeout: 3000,
  }),
  components: {
    MedicoHeader,
  },
  methods: {
    ...mapGetters('medico', { medico: 'medico' }),
    isAuthenticated() {
      return authService.getToken()
    },
    setDatadogUser() {
      if (this.isAuthenticated()) {
        const medico = this.medico()
        this.$setDatadogUser(medico.id.toString(), medico.nome, medico.email)
      }
    },
  },
  created() {
    this.$initDatadog()
    this.setDatadogUser()
    this.$root.$on('snackbar', (text: string, color: 'error' | 'success' | 'info') => {
      this.text = text
      this.color = color
      this.timeout = 3000
      this.snackbar = true
    })
  },
})
