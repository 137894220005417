import Vue from 'vue'
import VueTheMask, { mask } from 'vue-the-mask'
import './axios'
import './snackbar'
import './handleError'
import './datadog'

// Vue.use(Datadog)
Vue.directive('v-mask', mask)
Vue.use(VueTheMask)
