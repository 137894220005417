import Vue from 'vue'
import { datadogRum } from '@datadog/browser-rum';

declare module 'vue/types/vue' {
  interface Vue {
    $initDatadog(): void
    $setDatadogUser(id: string, nome: string, email:string): void
    $logoutDatadogUser(): void
  }
}

Vue.prototype.$initDatadog = function () {
  datadogRum.init({
    applicationId: '504c8f9a-3c14-465b-9daf-c272fd04ec40',
    clientToken: 'pubbd419e02e48362b299d2b404e92391b0',
    site: 'datadoghq.com',
    service: 'plataforma-médicos',
    env: process.env.VUE_APP_ENV.toLowerCase(),
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});

    Vue.prototype.$setDatadogUser = function (id: string, nome: string, email:string) {
    datadogRum.setUser({
      id,
      name: nome,
      email
    })
  }
  Vue.prototype.$logoutDatadogUser = function () {
    datadogRum.clearUser()
  }
}
